import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import Box from 'components/box'
import WorksListFull from 'components/worksListFull'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { fontSizeLead } from 'constants/typography'
import colors from 'constants/colors'
import MEDIA from 'helpers/mediaTemplates'

const PageTitle = styled.h1`
  font-size: 3rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  line-height: 1;
  padding: 0 1rem;
  font-weight: 400;

  ${MEDIA.MIN_TABLET`
    font-size: 5rem
  `};

  em {
    position: relative;
    background-image: linear-gradient(
      to right,
      ${colors.brandPurple},
      ${colors.brandPurple}
    );
    background-position: 0 1.04em;
    background-repeat: repeat-x;
    background-size: 8px 3px;

    ${MEDIA.MIN_TABLET`
    background-size: 8px 4px;
  `};
  }
`

const HomeText = styled.div`
  font-size: ${fontSizeLead};
`

const SectionTitle = styled.h2`
  margin-top: 0;
`

const SectionTitleWork = styled.h2`
  text-align: center;
  margin-bottom: 6rem;
  margin-top: 6rem;
`

const ServiceItem = styled.div`
  padding: 1rem 0;

  h5,
  p {
    margin: 0;
  }
`

const ServicesPanel = styled.div`
  margin: 2rem auto;
  max-width: 740px;
  background: #f5ebff;
  padding: 2rem;
`

const ServicesPanelWrapper = styled.div`
  padding: 0 1rem;
`

const WorksListWrapper = styled.div`
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto 4rem;
`

const Index = ({ data }) => (
  <Layout>
    <PageTitle
      dangerouslySetInnerHTML={{
        __html: data.homeJson.title,
      }}
    />
    <Box>
      <HomeText
        dangerouslySetInnerHTML={{
          __html: data.homeJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>
    <ServicesPanelWrapper>
      <ServicesPanel>
        <SectionTitle>Services</SectionTitle>
        {data.homeJson.services.map((service, index) => (
          <ServiceItem key={index}>
            <h5>{service.title}</h5>
            <p>{service.copy}</p>
          </ServiceItem>
        ))}
      </ServicesPanel>
    </ServicesPanelWrapper>

    <SectionTitleWork>Work</SectionTitleWork>
    <WorksListWrapper>
      <WorksListFull />
    </WorksListWrapper>
  </Layout>
)

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Index

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      services {
        title
        copy
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
